
import './App.css';

import * as React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  
} from "react-router-dom";

import About from './comonents/About';
import Home from  './comonents/Home'
import Header from './comonents/Header';
import Contact from './comonents/Contact';
import OurService from './comonents/OurService';
import User from './comonents/User';


const router = createBrowserRouter([{
  path:"/",
  element:<> <Header/>  <Home/>    </> 
},{
  path:"/about",
  element:<> <Header/>  <About/>    </> 
},
{
  path:"/service",
  element:<> <Header/>  <OurService/>    </> 

},
{
  path:"/contact",
  element:<> <Header/>  <Contact/>    </> 
},
{
  path:"/user",
  element:<> <Header/>  <User/>    </>
},
{
  path:"/user/:username",
  element:<> <Header/>  <User/>    </>
}
  
]);


function App() {
  return (
    <>

<RouterProvider router={router} />





    
    </>
  );
}

export default App;
