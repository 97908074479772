import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Header() {
  return (
    <div>
      <ul>
        <li>
          <NavLink className={(e) => 
            e.isActive ? "red" : ""
          } to="/">Home</NavLink>
        </li>
        <li>
          <NavLink className={(e) => 
            e.isActive ? "red" : ""
          } to="/about">About</NavLink>
        </li>
        <li>
          <NavLink className={(e) => 
            e.isActive ? "red" : ""
          } to="/service">Service</NavLink>
        </li>
        <li>
          <NavLink className={(e) => 
            e.isActive ? "red" : ""
          } to="/contact">Contact</NavLink>
        </li>
        <li>
          <NavLink className={(e) => 
            e.isActive ? "red" : ""
          } to="/user">User</NavLink>
        </li>
      </ul>
    </div>
  )
}
