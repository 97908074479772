import React from 'react'
import { useParams } from 'react-router-dom'

export default function User() {
    const param =useParams()
  return (
    <div>
      <h1>I am user :{param.username}</h1>
    </div>
  )
}
